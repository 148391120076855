import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BulletinFieldsRepository extends BaseRepository {
    baseUrl = 'management/specifications/bulletin-fields';

    /**
     * search bulletins by params
     *
     * @param bulletinFieldId
     * @param criteria
     * @return {*}
     */
    searchBulletins(bulletinFieldId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${bulletinFieldId}/bulletins`, criteria);
    }
}
