import { DialogService }                from 'aurelia-dialog';
import { inject }                       from 'aurelia-framework';
import { BulletinField }                from 'modules/management/models/bulletin-field';
import { BulletinFieldsBulletinsModal } from 'modules/management/specifications/bulletin-fields/bulletins-modal';

@inject(DialogService)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param dialogService
     */
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BulletinField}
     */
    model() {
        let model = new BulletinField();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.description = {
            type:       'text',
            key:        'description',
            label:      'form.field.description',
            size:       12,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        const schema = [
            [this.description],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.specifications.bulletin-fields.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.bulletinsButton = {
            type:       'button',
            label:      'form.field.bulletins',
            action:     () => this.openModal(viewModel.model.id, BulletinFieldsBulletinsModal),
            hidden:     !viewModel.model.hasBulletins,
            attributes: {
                class: 'btn btn-info',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-spreadsheet',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
                this.bulletinsButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService.open({ viewModel: viewModel, model: model });
    }
}
