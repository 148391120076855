import { DialogController }         from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { BaseListViewModel }        from 'base-list-view-model';
import { BulletinFieldsRepository } from 'modules/management/specifications/bulletin-fields/services/repository';
import { AppContainer }             from 'resources/services/app-container';

@inject(AppContainer, BulletinFieldsRepository, DialogController)
export class BulletinFieldsBulletinsModal extends BaseListViewModel {

    listingId = 'management-specifications-bulletin-modal-listing';

    @bindable headerTitle    = 'listing.management.specifications.bulletin-modal-fields';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.bulletins',
        size:    'modal-lg',
        buttons: false,
    };

    fullData = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletin-fields.manage',
            'management.specifications.bulletin-fields.create',
            'management.specifications.bulletins.revisions.manage',
            'management.specifications.bulletins.revisions.edit',
            'management.specifications.bulletins.revisions.fields.manage',
            'management.specifications.bulletins.revisions.fields.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.bulletinField = params;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.searchBulletins(criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'test_code',
                    name:  'bulletins.test_code',
                    title: 'form.field.test-code',
                },
                {
                    data:  'name',
                    name:  'bulletin_translations.name',
                    title: 'form.field.designation',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchBulletins(criteria) {
        return this.repository.searchBulletins(this.bulletinField, criteria);
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }
}
